import { graphql, Link, useStaticQuery } from "gatsby"
import { parse } from "graphql-compose/lib/graphql"
import * as React from "react"
import { useEffect, useState } from "react"
import { BiPurchaseTag } from "react-icons/bi"
import { FcCalendar } from "react-icons/fc"
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Pagination from "react-responsive-pagination"
import defaultEventPicture from "../../../images/defaultEventPicture.jpg"
import defaultUserProfile from "../../../images/defaultUserProfile.png"
import notFoundPicture from "../../../images/sad.png"
import "../../../styles/blogStyle/style.css"
import "../../common/i18n"
import { useTranslation } from "react-i18next"

const WorkoutsGrid = () => {
  const { t, i18n } = useTranslation()
  const workoutsGridData = useStaticQuery(graphql`
    query {
      allStrapiWorkouts {
        nodes {
          published_at
          roArticle
          title
          slug
          markdown
          feature_image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const [currentPage, setCurrentPage] = useState(1)
  const [showLoader, setShowLoader] = useState(false)
  const [anyArticlesToShow, setAnyArticlesToShow] = useState(false)

  const [articles, setArticles] = useState(
    workoutsGridData.allStrapiWorkouts.nodes.sort((a, b) => {
      return new Date(b.published_at) - new Date(a.published_at)
    })
  )

  const [totalPages, setTotalPages] = useState(
    Math.ceil(workoutsGridData.allStrapiWorkouts.nodes.length / 12)
  )

  const search = " "
  const replaceWith = "-"

  useEffect(() => {
    let timer1 = setTimeout(() => setShowLoader(true), 1000)
    return () => {
      clearTimeout(timer1)
    }
  }, [])

  useEffect(() => {
    setTotalPages(Math.ceil(articles.length / 12))
  }, [articles])

  const isRightLang = article => {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      if (localStorage.getItem("lang") === "ro" && article.roArticle) {
        if (!anyArticlesToShow) setAnyArticlesToShow(true)
        return true
      } else if (localStorage.getItem("lang") === "en" && !article.roArticle) {
        if (!anyArticlesToShow) setAnyArticlesToShow(true)
        return true
      }
      return false
    }
    return false
  }

  useEffect(() => {
    setAnyArticlesToShow(false)
  }, [window.localStorage.getItem("lang")])

  const notFound = () => {
    return (
      <div
        class="col-lg-12 col-md-12"
        style={{ marginTop: "12vh", opacity: 0.6 }}
      >
        <img
          src={notFoundPicture}
          style={{
            maxHeight: "15vh",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        ></img>
        <h2 style={{ textAlign: "center", fontSize: "3rem" }}>
          {t("workoutsGridComponent.notFound")}
        </h2>
      </div>
    )
  }
  return (
    <>
      {!showLoader && (
        <div className="loader-container">
          {" "}
          <Loader
            type="Grid"
            color="#D50000"
            height={100}
            width={100}
            timeout={1000}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      )}

      <div style={{ marginTop: "105px" }} />

      <div className="blog-area ptb-100">
        <div className="container" style={{ minHeight: "90vh" }}>
          <div className="row">
            {" "}
            <h1 className="title">{t("workoutsGridComponent.title")}</h1>
          </div>
          <div className="row">
            {articles?.length > 0 &&
              [...Array(12)].map(
                (x, i) =>
                  articles[(currentPage - 1) * 12 + i] &&
                  isRightLang(articles[(currentPage - 1) * 12 + i]) && (
                    <div key={i} className="col-lg-4 col-md-6">
                      <div className="single-blog-post bg-fffbf5">
                        <div className="post-image">
                          <Link
                            to={`/workout/${
                              articles[(currentPage - 1) * 12 + i].slug
                            }`}
                          >
                            <img
                              src={
                                articles[(currentPage - 1) * 12 + i]
                                  .feature_image
                                  ? articles[(currentPage - 1) * 12 + i]
                                      .feature_image.childImageSharp.fluid.src
                                  : defaultEventPicture
                              }
                              alt="image"
                            />
                          </Link>
                        </div>
                        <div className="post-content">
                          <ul className="post-meta d-flex justify-content-between align-items-center">
                            <li>
                              <div style={{ paddingBottom: "14px" }}>
                                <FcCalendar />
                                {articles[
                                  (currentPage - 1) * 12 + i
                                ].published_at.substring(0, 10)}
                              </div>
                            </li>
                          </ul>
                          <h3>
                            <Link
                              to={`/workout/${
                                articles[(currentPage - 1) * 12 + i].slug
                              }`}
                            >
                              {articles[(currentPage - 1) * 12 + i].title}
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  )
              )}
            {!anyArticlesToShow && notFound()}
            {anyArticlesToShow && (
              <div className="col-lg-12 col-md-12">
                <Pagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={setCurrentPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkoutsGrid
