import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import '../components/common/i18n';
import TopBar from '../components/common/topBar';
import Layout from '../components/layout';
import { Footer } from '../components/sections/footer/footer';
import WorkoutsGrid from '../components/sections/workouts/workoutsGrid';
import Seo from '../components/seo';

const WorkoutsPage = () => {
  const isBrowser = typeof window !== 'undefined';
  return (
    <Layout>
      <Seo
        title={
          isBrowser && window && window.localStorage.getItem('lang') === 'ro'
            ? 'Tobsos - Antrenamente'
            : 'Tobsos - Workouts'
        }
        description={
          isBrowser && window && window.localStorage.getItem('lang') === 'ro'
            ? 'Cu cât fundația fizică a jucătorilor este mai puternică, cu atât sunt mai mari șansele de a deveni un sportiv de top.'
            : 'The stronger the players physical foundation the higher the chances of becoming a top athlete.'
        }
        keywords="fitness, training, sport, young, tineret"
        url="https://tobsos.com/"
      />
      <TopBar />
      <WorkoutsGrid />
      <Footer />
    </Layout>
  );
};

export default WorkoutsPage;
